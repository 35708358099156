import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey, teal } from "@mui/material/colors";
import AOS from "aos";
import "aos/dist/aos.css";


import { Helmet } from "react-helmet";
const theme = createTheme({
  palette: {
    primary: {
      light: "#825b07",
      main: "#825b07",
      dark: "#825b07",
      contrastText: "#000",
    },
    secondary: {
      light: "#291002",
      main: "#291002",
      dark: "#291002",
      contrastText: "#fff",
    },
    third: {
      light: "#000000",
      main: "#000000",
      dark: "#000000",
      contrastText: "#000000",
    },
    fourth: {
      light: "FF0000",
      main: "FF0000",
      dark: "FF0000",
      contrastText: "#000",
    },
  },
  shadows: ["none"],
  typography: {
    fontFamily: ["Overlock"].join(","), fontWeight: 1000, letterSpacing: 2,
    button: {
      textTransform: "none",
    },
  },
});

const names = [
  "Event",
  "Wernisaż",
  "Wykład",
  "Imprezę firmową",
  "Spotkanie",
];

export default function Album() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  const [newName, setnewName] = useState("");
  const [index, setIndex] = useState(0);
  const [value, setValue] = React.useState(0);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const shuffle = useCallback(() => {
    setIndex((prevIndex) => prevIndex + 1);

    setnewName(names[index]);
  }, []);
  if (index > 4) {
    setIndex(0);
  }
  useEffect(() => {
    const interval = setInterval(shuffle, 1500);
    return () => clearInterval(interval);
  }, [shuffle]);

  // const shuffle = useCallback(() => {
  //   const index = Math.floor(Math.random() * names.length);
  //   setnewName(names[index]);
  // }, []);
  // useEffect(() => {
  //   const intervalID = setInterval(shuffle, 500);
  //   return () => clearInterval(intervalID);
  // }, [shuffle]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <main>
        {/* Hero unit */}
        {/* <CustomView condition={browserName !== "Safari"}> */}
        {/* <CustomView condition={browserName !== "Chrome"}>
          <div className="player-wrapper">
            <ReactPlayer
              // ref={videoParentRef}
              playsinline={true}
              className="react-player"
              url="https://youtu.be/iNeIt6UVsTQ"
              width="100vw"
              height="100%"
              volume={0}
              muted={true}
              playsInline={true}
              playing={true}
              loop={true}
              controls={false}
              playbackRate={1}
            />
          </div>
        </CustomView> */}

        <img className="homePic" width="100%" alt="" />
        <Box
          sx={{
            // backgroundColor: "secondary.main",
            // backgroundSize: "cover",
            // opacity: [0.8, 0.8, 0.8],
            height: "101vh",
          }}
        >
 
    
            <Container
              align="left"
              maxWidth="sm"
              sx={{
                pt: "1vh",
                display: {
                  xs: "block",
                  sm: "block",
                  md: "block",
                  lg: "block",
                  xl: "block",
                },
              }}
            >
              <br />
              <br />
              
              <br />
              <br />
            </Container>
         

          <Container
            data-aos="fade-right"
            maxWidth="md"
            sx={{
              alignItems: "center",
              // ml: "-2vw",
            }}
          >
            <Box
              sx={{
                // backgroundColor: "white",
                // backgroundSize: "cover",
                // opacity: [0.9, 0.9, 0.8],
                // minHeight: "95vh",
                alignItems: "center",
                py: 5,
                mb: 12,
              }}
            >
              <Container
                data-aos="zoom-in"
                data-aos-anchor-placement="top-bottom"
                maxWidth="md"
                sx={{
                  opacity: [0.1, 0.99, 1],
                }}
              >
                <Stack
                  // sx={{ pt: 5 }}
                  direction="column"
                  spacing={2}
                  justifyContent="center"
                  sx={{
                    py: 6,
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "block",
                      lg: "block",
                      xl: "block",
                    },
                  }}
                >
                  <Typography
                    component="h4"
                    variant="h4"
                    align="center"
                    color="white"
                    gutterBottom
                  >
                   Wynajmij Piwnicę pod Rynkiem Głównym na
                  </Typography>
                  <Typography
                    component="h2"
                    variant="h2"
                    align="center"
                    color="white"
                    // sx={{ pb: 12 }}
                  >
                    {names[index]}
                  </Typography>
                </Stack>{" "}
                <Container
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "flex",
                      lg: "flex",
                      xl: "flex",
                    },
                  }}
                >
                  <Typography
                    component="h1"
                    variant="h4"
                    align="justify"
                    color="white"
                    className="homeText"
                  >
                    Oferujemy Państwu możliwość wynajęcia na wieczór, pare dni, czy tygodni 160 metrów kwadratowych mieszczących się w wyjątkowej piwnicy znajdującej sie w Kaminicy 'Pod Słońcem' pod Krakowskim Rynkiem Głównym.
                  </Typography>
                </Container>
               
              </Container>
            </Box>
          </Container>
          <Helmet>
            <title>
              Piwnica Pod Słońcem Kraków Rynek Główny
            </title>
            <meta
              name="description"
              content="Wynajem piwnicy na Rynku Głównym w Krakowie"
            />
            <meta
              name="keywords"
              content="Rynek Główny Kraków, piwnica, wynajem, impreza, firmowa"
            />
            <link rel="canonical" href="https://artstrefa43.com/" />
          </Helmet>
        </Box>
      </main>
    </ThemeProvider>
  );
}
