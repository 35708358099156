import * as React from "react";
import "./App.css";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { grey, teal } from "@mui/material/colors";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from "./src/Contact";
import Rezerwacja from "./src/Rezerwacja";
import Home from "./src/Home";
import Info from "./src/Info";
import emailjs from "@emailjs/browser";
import Cookies from "./src/cookies.jsx";
import AML from "./src/aml.jsx";
import Lokalizacja from "./src/Lokalizacja.jsx";
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import DocumentMeta from "react-document-meta";
import Footer from "./src/Footer";
import NavPC from "./src/navPc.jsx";
import FAQ from "./src/FAQ.jsx";
import Cennik from "./src/Cennik.jsx";
import { Language } from "@mui/icons-material";


function Copyright() {
  return (
    <Typography variant="body2" color="white" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://cholujdesign.com/">
        CholujDesign S.C.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
function sendEmail(e) {
  e.preventDefault();

  emailjs
    .sendForm(
      "service_a1wwmva",
      "template_jnm4g0d",
      e.target,
      "user_x9kPpW1JmhYVbwVWmxAVD"
    )
    .then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
}

const theme = createTheme({
  palette: {
    primary: {
      light: "#825b07",
      main: "#825b07",
      dark: "#825b07",
      contrastText: "#000",
    },
    secondary: {
      light: "#291002",
      main: "#291002",
      dark: "#291002",
      contrastText: "#fff",
    },
    third: {
      light: "#000000",
      main: "#000000",
      dark: "#000000",
      contrastText: "#000000",
    },
  },
  shadows: ["none"],
  typography: {
    fontFamily: ["Overlock"].join(","), fontWeight: 500, letterSpacing: 2, fontStyle: 'oblique',
    textTransform: 'none', button: {
      textTransform: 'none'
    }
  },
});
const meta = {
  title: "Piwnica pod Słońcem",
  description: "Piwnica pod Słońcem",
  canonical: "https://artstrefa43.pl",
  meta: {
    charset: "utf-8",
    name: {
      keywords: "Piwnica, pod Słońcem, Artstrefa43",
    },
  },
};
export default function App() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };




  return (
    <ThemeProvider theme={theme}>
      <DocumentMeta {...meta}>

        <div className="App">
          <CssBaseline />

          <NavPC />
          <main>
            <BrowserRouter>
              <Routes>


                <Route path="/home" element={<Home />} />
                <Route path="/Kontakt" element={<Contact />} />
                <Route path="/Informacje" element={<Info />} />
                <Route path="/Lokalizacja" element={<Lokalizacja />} />
                <Route path="/FAQ" element={<FAQ />} />
                <Route path="/Cennik" element={<Cennik />} />
                <Route path="/Rezerwacje" element={<Rezerwacja />} />
                <Route path="/AML-RODO" element={<AML />} />



                <Route path="/:any" element={<Home />} />
                <Route path="/" element={<Home />} />
              </Routes>
            </BrowserRouter>
          </main>
          {/* Footer */}
          <Footer />
          {/* End footer */}
          <h1> Choluj Design </h1>
        </div></DocumentMeta>
    </ThemeProvider>
  );
}

