import React, { useEffect } from "react";

import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";

const text = [
  {
    one: "   ",
    onePirce: " ",
    two: "  d",
    twoPirce: " ",
    three: " ",
    threePirce: " ",
    four: " ",
    fourPirce: " ",
    five: " ",
    fivePirce: " ",
    six: " ",
    sixPirce: " ",
  },
];

function PricingContent() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <React.Fragment>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      {/* Hero unit */}
      <main>

        <Container maxWidth="lg">

          <Box
            sx={{

              minHeight: "80vh",
            }}
          >
            <Container
              data-aos="fade-down"
              sx={{
                pt: 5,
                pb: 22,
                // backgroundColor: "secondary.main",
              }}
            >
              <Typography
                component="h1"
                variant="h4"
                align="center"
                color=""
                gutterBottom
                sx={{
                  backgroundColor: "primary",
                }}
              >
                Cennik
                <hr />
              </Typography>
              <Typography component="h1"
                variant="h5" align="center" gutterBottom>Sala nr 1 - 500LN/dzień</Typography>
              <Typography component="h1"
                variant="h5" align="center" gutterBottom>Sale nr 2, 3 - 650 PLN/dzień</Typography>
                 <Typography component="h1"
                variant="h5" align="center" gutterBottom>Sala nr 4 - 450 PLN/dzień</Typography>
              <Typography component="h1"
                variant="h5" align="center" gutterBottom>Cała piwnica - 990 PLN/dzień</Typography>
              <Typography component="h1"
                variant="h5" align="center" gutterBottom>Wynajem na ponad 3 dni -10%</Typography>
              <Typography component="h1"
                variant="h5" align="center" gutterBottom>Wynajem na ponad 7 dni -20%</Typography>
            </Container>
            <Typography component="h1"
                variant="h5" align="center" gutterBottom>W celu uzyskania faktury VAT prosimy o wcześniejszy kontakt.
                <br/>
                Cena nie zawiera kosztów sprzątania.
                </Typography>
          </Box>
          <Helmet>
            <title>Cennik</title>
            <meta name="description" content="Cennik" />
            <link
              rel="canonical"
              href="http://artstrefa43.com/Cennik"
            />
          </Helmet>
        </Container>
      </main>
    </React.Fragment>
  );
}

export default function Pricing() {
  return <PricingContent />;
}
